var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import gql from "graphql-tag";
import { mapState } from "vuex";
import find from "lodash/find";
import { addMoney, copyAddressWithoutGraphQlFields, createEmptyCartPersonalInformationEntry, } from "../../../data";
import SignInOrPersonalInformationForm from "../../ui/SignInOrPersonalInformationForm.vue";
import Shipping from "./Shipping.vue";
import Payment from "./Payment.vue";
import HealthCoverForm from "./HealthCoverForm.vue";
import PaymentMixin from "../../ui/payment/PaymentMixin";
import { billingFormDetailsToPaymentSource } from "../../ui/payment/BillingDetailsData";
import WeChatPayCodeDialog from "../../ui/WeChatPayCodeDialog.vue";
import mixins from "vue-typed-mixins";
import CartPaymentPipeline from "./CartPaymentPipeline";
import { createStripe } from "../../../utils/stripe";
import getAbsoluteUrl from "../../../router/getAbsoluteUrl";
import PersonalInformationLoading from "../general/PersonalInformationLoading.vue";
export default mixins(PaymentMixin).extend({
    components: {
        SignInOrPersonalInformationForm: SignInOrPersonalInformationForm,
        Shipping: Shipping,
        Payment: Payment,
        HealthCoverForm: HealthCoverForm,
        WeChatPayCodeDialog: WeChatPayCodeDialog,
        PersonalInformationLoading: PersonalInformationLoading,
    },
    apollo: {
        viewerStudent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n            }\n            ...Payment_viewerStudent\n          }\n        }\n        ", "\n      "], ["\n        {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n            }\n            ...Payment_viewerStudent\n          }\n        }\n        ", "\n      "])), Payment.options.fragments.viewerStudent),
            result: function () {
                this.tryAndPopulateShipping();
            },
        },
        cart: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query CheckoutQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            requiresShipping\n            shippingOptions {\n              id\n              price {\n                amount\n                currency\n              }\n              priceAud\n            }\n            healthCovers {\n              __typename\n              id\n            }\n            total {\n              amount\n              currency\n            }\n            totalAud\n            extraPersonalFields\n            ...Payment_cart\n            ...Shipping_cart\n            ...HealthCoverForm_cart\n          }\n        }\n        ", "\n        ", "\n        ", "\n      "], ["\n        query CheckoutQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            requiresShipping\n            shippingOptions {\n              id\n              price {\n                amount\n                currency\n              }\n              priceAud\n            }\n            healthCovers {\n              __typename\n              id\n            }\n            total {\n              amount\n              currency\n            }\n            totalAud\n            extraPersonalFields\n            ...Payment_cart\n            ...Shipping_cart\n            ...HealthCoverForm_cart\n          }\n        }\n        ", "\n        ", "\n        ", "\n      "])), Payment.options.fragments.cart, Shipping.options.fragments.cart, HealthCoverForm.options.fragments.cart),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
            result: function () {
                this.tryAndPopulateShipping();
            },
        },
    },
    // TODO: Redirect to cart page if no cart
    data: function () {
        return {
            step: "personal",
            personalInformation: createEmptyCartPersonalInformationEntry(),
            shipping: undefined,
        };
    },
    computed: __assign({ extraFields: function () {
            if (!this.cart) {
                return [];
            }
            return this.cart.extraPersonalFields;
        }, paymentPipeline: function () {
            return new CartPaymentPipeline({
                $apollo: this.$apollo,
                $t: this.$t.bind(this),
                $router: this.$router,
                $store: this.$store,
                stripe: createStripe(this.stripePublishableKey),
            });
        }, paymentStartInput: function () {
            if (!this.cart || !this.billingFormDetails || !this.personalInformation) {
                throw new Error("Invalid state, propertyListingPayment null");
            }
            var _a = this.personalInformation, saveForNextTime = _a.saveForNextTime, alreadyInAustralia = _a.alreadyInAustralia, // eslint-disable-line @typescript-eslint/no-unused-vars
            purchasingAgent = _a.purchasingAgent, personalInformation = __rest(_a, ["saveForNextTime", "alreadyInAustralia", "purchasingAgent"]);
            var billingAddress = this.billingFormDetails.billingAddress;
            if (billingAddress === "same-as-shipping") {
                if (!this.shipping) {
                    throw new Error("Invalid state, no shipping");
                }
                billingAddress = this.shipping.address;
            }
            var shippingPrice = {
                amount: "0",
                currency: this.cart.total.currency,
            };
            var shippingPriceAud = "0";
            if (this.shipping) {
                var shippingId_1 = this.shipping.id;
                var shippingOption = find(this.cart.shippingOptions, function (_a) {
                    var id = _a.id;
                    return id === shippingId_1;
                });
                if (!shippingOption) {
                    throw new Error("Invalid state");
                }
                shippingPrice = shippingOption.price;
                shippingPriceAud = shippingOption.priceAud;
            }
            return {
                saveForNextTime: saveForNextTime,
                billingAddress: {
                    address: billingAddress,
                    name: "".concat(personalInformation.firstName, " ").concat(personalInformation.lastName),
                },
                cartId: this.cart.id,
                shipping: this.cart.requiresShipping ? this.shipping : undefined,
                paymentSource: billingFormDetailsToPaymentSource(this.billingFormDetails),
                personalInformation: personalInformation,
                purchasingAgent: purchasingAgent,
                amountAud: addMoney({ currency: "AUD", amount: this.cart.totalAud }, { currency: "AUD", amount: shippingPriceAud }).amount,
                amount: addMoney(this.cart.total, shippingPrice),
                returnUrl: getAbsoluteUrl(this.$store, this.$router, "/purchase/".concat(this.cart.id, "/success")),
            };
        } }, mapState(["cartId", "stripePublishableKey"])),
    mounted: function () {
        if (!this.cartId) {
            this.$router.push("/cart");
        }
    },
    methods: {
        tryAndPopulateShipping: function () {
            if (this.shipping) {
                return;
            }
            if (!this.cart ||
                !this.viewerStudent ||
                !this.viewerStudent.shippingAddress) {
                return;
            }
            if (!this.cart.shippingOptions.some(function (_a) {
                var id = _a.id;
                return id === "flat-rate";
            })) {
                return;
            }
            this.shipping = {
                id: "flat-rate",
                address: copyAddressWithoutGraphQlFields(this.viewerStudent.shippingAddress),
            };
        },
        onPersonalInformationSubmit: function () {
            if (!this.cart) {
                throw new Error("Invalid state, no cart");
            }
            if (this.cart.healthCovers.length > 0) {
                this.step = "health-cover";
            }
            else if (this.cart.requiresShipping) {
                this.step = "shipping";
            }
            else {
                this.step = "payment";
            }
        },
        onHealthCoverSubmit: function () {
            if (!this.cart) {
                throw new Error("Invalid state, no cart");
            }
            if (this.cart.requiresShipping) {
                this.step = "shipping";
            }
            else {
                this.step = "payment";
            }
        },
        onShippingSubmit: function () {
            this.step = "payment";
        },
    },
});
var templateObject_1, templateObject_2;
