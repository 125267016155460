var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import AsyncStatus from "../../../utils/async";
import AddressFieldSet from "../../dashboard/AddressFieldSet.vue";
import gql from "graphql-tag";
import { moneyFormat } from "../../../utils/filters";
export default Vue.extend({
    components: {
        AddressFieldSet: AddressFieldSet,
    },
    fragments: {
        cart: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment Shipping_cart on Cart {\n        id\n        shippingOptions {\n          id\n          description\n          price {\n            amount\n            currency\n          }\n        }\n      }\n    "], ["\n      fragment Shipping_cart on Cart {\n        id\n        shippingOptions {\n          id\n          description\n          price {\n            amount\n            currency\n          }\n        }\n      }\n    "]))),
    },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        cart: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    computed: {
        valueOrEmpty: function () {
            return this.value || {};
        },
        useAddressField: function () {
            return this.value && this.value.id === "flat-rate";
        },
        shippingOptions: function () {
            var _this = this;
            var getLabel = function (_a) {
                var id = _a.id, price = _a.price, description = _a.description;
                if (id === "flat-rate") {
                    return _this.$t("Ship the product to me and add {fee} shipping fee", {
                        fee: moneyFormat(price),
                    }).toString();
                }
                return description;
            };
            return this.cart.shippingOptions.map(function (option) { return ({
                value: option.id,
                label: getLabel(option),
            }); });
        },
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.status.run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                if (!_this.useAddressField) {
                    _this.onInput("address", undefined);
                }
                _this.$emit("submit");
            }));
        },
        onInput: function (fieldName, fieldValue) {
            var _a;
            this.$emit("input", __assign(__assign({}, this.valueOrEmpty), (_a = {}, _a[fieldName] = fieldValue, _a)));
        },
    },
});
var templateObject_1;
