var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import PaymentForm from "../../ui/payment/PaymentForm.vue";
import find from "lodash/find";
import Vue from "vue";
function isPaymentFormRef(ref) {
    return "resetPaymentProcess" in ref;
}
export default Vue.extend({
    components: {
        PaymentForm: PaymentForm,
    },
    props: {
        viewerStudent: {
            type: Object,
            default: null,
        },
        cart: {
            type: Object,
            required: true,
        },
        shipping: {
            type: Object,
            default: undefined,
        },
        personalInformation: {
            type: Object,
            required: true,
        },
        process: {
            type: Function,
            required: true,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment Payment_viewerStudent on Student {\n        id\n        ...PaymentForm_viewerStudent\n      }\n      ", "\n    "], ["\n      fragment Payment_viewerStudent on Student {\n        id\n        ...PaymentForm_viewerStudent\n      }\n      ", "\n    "])), PaymentForm.options.fragments.viewerStudent),
        cart: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      fragment Payment_cart on Cart {\n        id\n        subTotal {\n          amount\n          currency\n        }\n        discount {\n          amount\n          currency\n        }\n        requiresShipping\n        shippingOptions {\n          id\n          description\n          price {\n            amount\n            currency\n          }\n        }\n      }\n    "], ["\n      fragment Payment_cart on Cart {\n        id\n        subTotal {\n          amount\n          currency\n        }\n        discount {\n          amount\n          currency\n        }\n        requiresShipping\n        shippingOptions {\n          id\n          description\n          price {\n            amount\n            currency\n          }\n        }\n      }\n    "]))),
    },
    computed: {
        shippingPrice: function () {
            var _this = this;
            if (!this.shipping) {
                return undefined;
            }
            return find(this.cart.shippingOptions, function (_a) {
                var id = _a.id;
                return id === _this.shipping.id;
            }).price;
        },
        shippingAddress: function () {
            if (!this.shipping) {
                return undefined;
            }
            return this.shipping.address;
        },
        billingName: function () {
            var _a = this.personalInformation, firstName = _a.firstName, lastName = _a.lastName;
            return "".concat(firstName, " ").concat(lastName);
        },
    },
    methods: {
        resetPaymentProcess: function () {
            var paymentForm = this.$refs.paymentForm;
            if (!isPaymentFormRef(paymentForm)) {
                throw new Error("Payment form not found");
            }
            paymentForm.resetPaymentProcess();
        },
    },
});
var templateObject_1, templateObject_2;
