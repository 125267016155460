var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import gql from "graphql-tag";
import compact from "lodash/compact";
import pick from "lodash/pick";
import AsyncStatus from "../../../utils/async";
import { fromApiDate, toApiDate } from "../../../api/transform";
import populateCustomHealthCover from "../../../api/mutations/populateCustomHealthCover";
import { allCountriesOptions, genderOptions } from "../../../data";
export default Vue.extend({
    fragments: {
        cart: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment HealthCoverForm_cart on Cart {\n        id\n        healthCovers {\n          __typename\n          id\n          coverFor\n          coverForDisplay\n          courseStartDate\n          courseEndDate\n          partnerPerson {\n            firstName\n            lastName\n            gender\n            passportNumber\n            passportCountry\n            dateOfBirth\n          }\n          children {\n            firstName\n            lastName\n            gender\n            passportNumber\n            passportCountry\n            dateOfBirth\n          }\n        }\n      }\n    "], ["\n      fragment HealthCoverForm_cart on Cart {\n        id\n        healthCovers {\n          __typename\n          id\n          coverFor\n          coverForDisplay\n          courseStartDate\n          courseEndDate\n          partnerPerson {\n            firstName\n            lastName\n            gender\n            passportNumber\n            passportCountry\n            dateOfBirth\n          }\n          children {\n            firstName\n            lastName\n            gender\n            passportNumber\n            passportCountry\n            dateOfBirth\n          }\n        }\n      }\n    "]))),
    },
    props: {
        cart: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            genderOptions: genderOptions,
            countryOptions: allCountriesOptions,
            status: new AsyncStatus(),
            form: {
                personalDetailsForms: this.cart.healthCovers.map(function (cover) {
                    var numPersonal;
                    switch (cover.coverFor) {
                        case "couple":
                            numPersonal = 1;
                            break;
                        case "1-kid":
                            numPersonal = 2;
                            break;
                        case "2-kids":
                            numPersonal = 3;
                            break;
                        case "3-kids":
                            numPersonal = 4;
                            break;
                        default:
                            numPersonal = 0;
                    }
                    var existing = compact(__spreadArray([cover.partnerPerson], cover.children, true));
                    return Array(numPersonal)
                        .fill(null)
                        .map(function (_, i) { return (__assign(__assign({ firstName: "", lastName: "", gender: null, passportNumber: "", passportCountry: null }, (existing[i]
                        ? pick(existing[i], "firstName", "lastName", "gender", "passportNumber", "passportCountry")
                        : undefined)), { dateOfBirth: existing[i]
                            ? fromApiDate(existing[i].dateOfBirth)
                            : null })); });
                }),
            },
        };
    },
    computed: {
        valueOrEmpty: function () {
            return this.value || {};
        },
    },
    methods: {
        formatDate: function (rawDate) {
            return fromApiDate(rawDate).format("DD MMM YYYY");
        },
        onSubmit: function () {
            var _this = this;
            this.status.run((function () { return __awaiter(_this, void 0, void 0, function () {
                var valid;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.$validator.validate()];
                        case 1:
                            valid = _a.sent();
                            if (!valid) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.$apollo.mutate(populateCustomHealthCover({
                                    healthCovers: this.cart.healthCovers.map(function (healthCover, i) { return ({
                                        id: healthCover.id,
                                        personDetails: _this.form.personalDetailsForms[i].map(function (_a) {
                                            var dateOfBirth = _a.dateOfBirth, detailsRest = __rest(_a, ["dateOfBirth"]);
                                            return (__assign({ dateOfBirth: toApiDate(dateOfBirth) }, detailsRest));
                                        }),
                                    }); }),
                                }))];
                        case 2:
                            _a.sent();
                            this.$emit("submit");
                            return [2 /*return*/];
                    }
                });
            }); })());
        },
    },
});
var templateObject_1;
